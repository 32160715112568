<template>
    <!--侧边一级导航-->
    <el-menu
            class="el-menu-vertical-demo"
            :collapse="$store.state.isCollapse"
            router
            style="height: 100%"
            :default-active="$store.state.activePath"
            background-color="#001529"
            text-color="#fff"
            active-text-color="#ffd04b"
    >
        <span style="color: #fff;">{{$store.state.activePath}}</span>
        <div class="header">
            <div class="title" v-show="!$store.state.isCollapse">清华艺术类阅卷系统</div>
        </div>
        <div class="menu-area">
            <div v-for="(item, index) in $store.state.menuList" :key="index">
                <template v-if="hasOneShowingChild(item.children, item)">
                    <!--考试类型为2时 不展示examTypeHidden==true-->
                    <el-menu-item
                            v-if="(item.examTypeHidden<3 && $store.state.examInfo.exam_type!=2)
                                ||(item.examTypeHidden>1 && $store.state.examInfo.exam_type==2)"
                            :index="resolvePath(item.path, item.children[0]['path'])"
                            @click="activeHandle(resolvePath(item.path, item.children[0]['path']))"
                    >
                        <span slot="title">
                          {{ item.children[0]["meta"]["title"] }}
                          <span class="show-num"
                                v-if="item.children[0]['showNum']&&$store.state.examInfo.exam_type != 2">
                            (<i>{{ item["children"][0]["meta"]["score_sum"] }}, </i>
                            <i>{{ item["children"][0]["meta"]["score_sum_cost"] }}</i>)
                          </span>
                        </span>
                    </el-menu-item>
                </template>
                <template v-else>
                    <el-submenu
                            v-if="(item.examTypeHidden<3 && $store.state.examInfo.exam_type!=2)
                                ||(item.examTypeHidden>1 && $store.state.examInfo.exam_type==2)"
                            :index="item.path"
                            :class="
                              $store.state.activePath.indexOf(item.path) != -1
                                ? 'active-child'
                                : ''
                            "
                    >
                        <template slot="title">
                            <span>{{ item.meta.title }}</span>
                        </template>

                        <el-menu-item
                                v-for="(item2, index2) in item.children"
                                :key="index2"
                                :index="resolvePath(item.path, item2.path)"
                                @click="activeHandle(resolvePath(item.path, item2.path))"
                                v-if="!item2.hidden"
                        >
                      <span slot="title">
                        {{ item2.meta.title }}
                        <span class="show-num" v-if="item2.showNum&&$store.state.examInfo.exam_type != 2">
                          (<i>{{ item2.meta.my_score_num }}, </i>
                          <i>{{ item2.meta.cost }}</i
                          >)
                        </span>
                      </span>
                        </el-menu-item>
                    </el-submenu>
                </template>
            </div>
        </div>
    </el-menu>
</template>

<script>
    import path from "path";

    export default {
        name: "Aside",
        data() {
            return {};
        },
        created() {
            let activePath = window.sessionStorage.getItem("index-activePath");
            if (activePath) {
                this.$store.state.activePath =
                    window.sessionStorage.getItem("index-activePath");
            } else {
                this.$store.state.activePath = '/mark/giveMark';
                window.sessionStorage.setItem(
                    "index-activePath",
                    this.$store.state.activePath
                );
            }
        },
        methods: {
            resolvePath(basePath, routePath) {
                return path.resolve(basePath, routePath);
            },
            hasOneShowingChild(children = [], parent) {
                const showingChildren = children.filter((item) => {
                    if (item.hidden) {
                        return false;
                    } else {
                        return true;
                    }
                });

                if (showingChildren.length === 1) {
                    return true;
                }

                if (showingChildren.length === 0) {
                    return true;
                }

                return false;
            },
            // 保存当前激活的分类
            activeHandle(active) {
                if (window.sessionStorage.getItem("index-activePath") != active) {
                    this.$store.state.isCollapse = false;
                } else {
                    this.$store.state.isCollapse = this.$store.state.isCollapse;
                }
                window.sessionStorage.setItem("index-activePath", active);
                this.$store.state.activePath = active;
                this.$router.push(active);
            },

            // 控制是否水平折叠收起菜单
            collapseStatus() {
                this.$store.state.isCollapse = !this.$store.state.isCollapse;
            },
        },
    };
</script>

<style scoped lang="scss">
    ::v-deep .el-submenu {
        .el-submenu__title {
            height: 60px;
            line-height: 60px;
            border-left: 4px solid transparent;
            color: #ffffff !important;
            opacity: 0.8;
            font-size: 20px;

            i {
                color: #ffffff !important;
            }

            &:hover {
                opacity: 1;
            }
        }

        &.active-child .el-submenu__title {
            opacity: 1;
        }
    }

    .el-menu {
        .el-menu-item {
            font-size: 20px;
            height: 60px;
            line-height: 60px;
            border-left: 4px solid transparent;
            font-family: "Source Han Sans CN";
            font-weight: 400;
            color: #ffffff !important;
            opacity: 0.8;
            overflow: hidden;

            > span {
                vertical-align: top;
            }

            &:hover {
                opacity: 1;
            }
        }

        .el-menu-item i {
            font-style: normal !important;
        }

        .show-num {
            margin-left: 10px;

            i:nth-child(1) {
                color: red;
            }

            i:nth-child(2) {
                color: #01a7f0;
            }
        }

        .is-active {
            border-color: #563279;
            border-radius: 4px;
            color: #ffffff !important;
            opacity: 1;
            background-color: #563279 !important;
        }
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 100%;
        // min-height: auto;
        min-height: 340px;
        background-color: #2c303e;
    }

    .el-menu--collapse {
        width: 80px;
        background-color: #2c303e;

        .el-menu-item {
            padding: 0;
        }
    }

    .el-menu-vertical-demo {
        position: relative;
        overflow: hidden;

        .header {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            background-color: #00284d;
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 60px;
            z-index: 100;

            .title {
                font-size: 28px;
                font-weight: bold;
                color: #ffffff;
            }

            .btnCollapse {
                text-align: center;
                height: 30px;
                line-height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 27px;
                    color: #ffffff;
                }
            }
        }

        .menu-area {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 60px 0 20px 0 !important;
        }
    }
</style>
