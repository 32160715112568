<template>
    <el-container class="index-container">
        <el-aside
                :width="$store.state.isCollapse ? '0px' : '24%'"
                :style="$store.state.isCollapse ? 'transition: width .3s;' : ''"
        >
            <Aside style="height: 100%"></Aside>
        </el-aside>
        <el-container>
            <el-header>
                <header-box></header-box>
            </el-header>
            <el-main>
                <router-view :key="key"/>
            </el-main>
        </el-container>
        <el-dialog
                title="退出"
                :visible.sync="$store.state.loginOutDialog"
                :closeOnClickModal="false"
                width="420px"
                :before-close="onCancel">
            <el-form :model="ruleForm"
                     :rules="rules"
                     ref="ruleFormRef"
                     label-width="0">
                <el-form-item label="" prop="password">
                    <el-input v-model="ruleForm.password"
                              type="password"
                              placeholder="请输入退出密码"
                              show-password
                              auto-complete="new-password"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="onCancel">取 消</el-button>
                <el-button type="primary" @click="onConfirm">确 定</el-button>
            </div>
        </el-dialog>
        <msg-dialog
                :dialogVisibleFu="$store.state.msgDialog"
                :msgFu="$store.state.showMsg"
                @closeDialog="closeMsgDialog"
        ></msg-dialog>
    </el-container>
</template>
<script>
    import {loginOut} from "../api/login";
    import Aside from "../components/Aside";
    import HeaderBox from "../components/Header";
    import MsgDialog from "../components/MsgDialog";
    import {mapMutations} from "vuex";

    export default {
        name: "Index",
        components: {
            Aside,
            HeaderBox,
            MsgDialog,
        },
        data() {
            return {
                ruleForm: {
                    password: '',
                },
                rules: {
                    password: [
                        {required: true, message: '请输入退出密码', trigger: 'blur'},
                    ],
                },
            };
        },
        created() {
            let _this = this;
            _this.$store.commit('updateLoginOutDialog', false);
        },
        methods: {
            ...mapMutations([
                "loginOut"
            ]),
            onCancel() {
                let _this = this;
                _this.$refs['ruleFormRef'].resetFields();
                _this.$store.commit('updateLoginOutDialog', false);
            },
            onConfirm() {
                let _this = this;
                _this.$refs['ruleFormRef'].validate((valid) => {
                    if (!valid) return false;
                    loginOut(_this.ruleForm)
                        .then(res => {
                            console.log(res)
                            if (res.data.code != 200) return _this.$message.error(res.data.message)
                            _this.loginOut();
                            _this.onCancel();
                            _this.$message.success(res.data.message)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                });
            },
            // 子组件触发，关闭msg弹框
            closeMsgDialog(val, type) {
                this.$store.state.msgDialog = val;
                this.$store.state.showMsg = '';
            },
        },
        computed: {
            key() {
                return this.$route.path
            }
        },
        watch: {
            '$route.path'(val) {
                let activePath = '';
                if (val == '/wait/giveMark') {
                    activePath = '/wait/list';
                } else if (val == '/anomaly/giveMark') {
                    activePath = '/anomaly/list';
                }  else if (val == '/normal/giveMark') {
                    activePath = '/normal/list';
                } else if (val == '/score/giveMark') {
                    activePath = this.$store.fromPath;
                } else {
                    activePath = val;
                }

                this.$store.state.activePath = activePath;
                window.sessionStorage.setItem("index-activePath", activePath);
            }
        }
    };
</script>
<style lang="scss">
    .index-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        min-width: 1000px;

        .el-aside {
            /*overflow-y: auto;*/
            /*overflow-x: hidden;*/
            overflow: hidden;
            max-width: 320px !important;
        }

        .el-container {
            /*min-width: 1024px;*/
            flex: 1;
            height: 100%;
            overflow: hidden;
            position: relative;

            .el-header {
                height: 60px;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                /*padding: 0;*/
                background-color: #ffffff;
                z-index: 999;

            }

            .el-main {
                width: 100%;
                height: 100%;
                padding-top: 80px;
                overflow-y: auto;
                background-color: #F0F2F5;
            }
        }
    }
</style>
