<template>
    <div id="Header">
        <div class="header-lf">
            <div class="btnCollapse" @click="collapseStatus()">
                <i v-show="!$store.state.isCollapse" class="el-icon-s-fold"></i>
                <i v-show="$store.state.isCollapse" class="el-icon-s-unfold"></i>
            </div>
            <div class="status">{{$store.state.assessObj.name}}</div>
            <div class="status">{{$store.state.subjectObj.subject_name}}</div>
            <!--<div class="status">
                <el-select v-model="$store.state.assessObj"
                           value-key="assess_num"
                           placeholder="请选择轮次"
                           @change="onChangeAssessNum"
                >
                    <el-option
                            v-for="(item, index) in confAssessNum"
                            :key="index"
                            :label="item.name"
                            :value="item"
                    ></el-option>
                </el-select>
                <el-select v-model="$store.state.subjectObj"
                           value-key="subject_uuid"
                           placeholder="请选择科目"
                           @change="onChangeSubject"
                >
                    <el-option
                            v-for="(item, index) in confSubject"
                            :key="index"
                            :label="item.subject_name"
                            :value="item"
                    ></el-option>
                </el-select>
            </div>-->
        </div>
        <div class="header-rt">
            <el-button type="primary"
                       v-if="($store.state.teacherType == 1||$store.state.teacherType == 3)
                              && $store.state.examInfo.exam_type != 2"
                       @click="endAssess">阅卷结束
            </el-button>
            <el-dropdown :hide-on-click="false" @command="handleCommand">
                <span class="el-dropdown-link">
                    <el-image class="user-img" :src="require('../assets/image/user-img.png')"></el-image>
                    <div>{{userInfo.username}}</div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
    import {loginOut} from "../api/login";
    import {mapMutations} from "vuex";
    import {yjAssessNumEnd, yjAssessNumEnd2} from "../api/mark";

    export default {
        name: "Header",
        data() {
            return {
                confAssessNum: [],
                confSubject: [],
                subject_uuid: null,
                assess_num: null,
                userInfo: {},
            }
        },
        created() {
            this.confAssessNum = this.$store.state.conf_assess_num;
            this.confSubject = this.$store.state.conf_subject;
            let userInfo = window.localStorage.getItem('user-info');
            if (userInfo) {
                this.userInfo = JSON.parse(userInfo);
            }
        },
        methods: {
            ...mapMutations([
                "updateAssessNum",
                "updateSubjectUuid",
                "loginOut"
            ]),
            // 结束阅卷
            endAssess() {
                this.$confirm('确认阅卷结束?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (this.$store.state.teacherType == 1) {
                        this.requestEndAssess(yjAssessNumEnd, 1)
                    } else if (this.$store.state.teacherType == 3) {
                        this.requestEndAssess(yjAssessNumEnd2)
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });

            },
            requestEndAssess(api, type = false) {
                let obj = {};
                if (type) obj['qz'] = type;
                api(obj)
                    .then(res => {
                        if (res.data.code != 200) {
                            if (res.data.code == 40000123
                                && this.$store.state.examInfo.exam_ts > 0) {
                                this.$store.state.msgDialog = true;
                                this.$store.state.showMsg = res.data.message;
                                return false;
                            }
                            return this.$message.error(res.data.message);
                        }

                        this.$message.success(res.data.message);
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            onChangeAssessNum(val) {
                let _this = this;
                _this.updateAssessNum(val);
                _this.$store.dispatch('GenerateRoutes')
            },
            onChangeSubject(val) {
                let _this = this;
                _this.updateSubjectUuid(val);
                _this.$store.dispatch('GenerateRoutes')
            },
            // 控制是否水平折叠收起菜单
            collapseStatus() {
                this.$store.state.isCollapse = !this.$store.state.isCollapse;
            },
            handleCommand(val) {
                if (val == 1) {
                    // 退出
                    this.logOut();
                }
            },
            logOut() {
                let _this = this;
                _this.$store.commit('updateLoginOutDialog', true);
                // _this.$confirm('确认退出登录?', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(async () => {
                //     await loginOut();
                //     setTimeout(() => {
                //         _this.loginOut();
                //     }, 100)
                //
                // }).catch(() => {
                //     _this.$message({
                //         type: 'info',
                //         message: '已取消操作'
                //     });
                // });
            }
        }
    }
</script>

<style scoped lang="scss">
    #Header {
        width: 100%;
        height: 60px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;


        > div {
            display: flex;
            flex-flow: row;
            align-items: center;

            .btnCollapse {
                text-align: center;
                height: 30px;
                line-height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                i {
                    font-size: 27px;
                    color: #999999;
                }
            }

            .status {
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                margin-left: 20px;

                .el-select:nth-child(1) {
                    width: 130px;
                    margin-right: 10px;
                }

                .el-select:nth-child(2) {
                    width: 180px;
                }

                ::v-deep .el-input.is-focus {
                    .el-input__inner {
                        border-color: #5c307d;
                    }
                }

                ::v-deep .el-input__inner {
                    &:hover,
                    &:focus {
                        border-color: #5c307d;
                    }
                }
            }

            .el-dropdown {
                cursor: pointer;
                margin-left: 50px;

                .el-dropdown-link {
                    display: inline-block;
                    height: 40px;
                    display: flex;
                    flex-flow: row;
                    align-items: center;

                    .user-img {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
</style>
