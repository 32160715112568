<template>
    <el-dialog
            title="提示"
            :visible.sync="messageDialog"
            :closeOnClickModal="false"
            :append-to-body="false"
            v-loading="loading"
            width="420px"
            class="hint"
            :before-close="()=>onClose()"
            @open="onOpen">
        <div style="line-height: 28px;font-size: 16px;">
            {{msg}}
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onClose()">关闭</el-button>
            <el-button type="primary"
                       @click="onConfirm()"
                       v-if="$store.state.examInfo.exam_ts==2"
            >结束阅卷
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {setYjScoreTs, yjAssessNumEnd} from "../api/mark";

    export default {
        name: "MsgDialog",
        props: {
            dialogVisibleFu: Boolean,
            msgFu: String,
        },
        data() {
            return {
                loading: false,
                messageDialog: this.dialogVisibleFu,
                msg: this.msgFu,
            }
        },
        methods: {
            onOpen() {
                setYjScoreTs({})
                    .then(res => {
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            onClose(type = false) {
                let _this = this;
                _this.messageDialog = false;
                _this.$emit('closeDialog', this.messageDialog, type);
            },
            onConfirm() {
                let _this = this;
                _this.requestEndAssess();
            },
            requestEndAssess() {
                if(this.loading){
                    return false;
                }
                this.loading = true;
                yjAssessNumEnd({
                    qz: 2
                })
                    .then(res => {
                        if (res.data.code != 200) {
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.$message.success(res.data.message);
                        this.onClose(true);
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            },
        },
        watch: {
            dialogVisibleFu() {
                this.messageDialog = this.dialogVisibleFu;
            },
            msgFu() {
                this.msg = this.msgFu;
            },
        }
    }
</script>

<style scoped lang="scss">
    .hint {
        ::v-deep .el-dialog {
            min-width: auto;
            max-width: inherit;
            height: inherit !important;

            .el-dialog__header {
                text-align: center;
            }

            .el-dialog__body {
                text-align: left;
            }

            .el-dialog__footer {
                text-align: center;

                .el-button + .el-button {
                    margin-left: 60px;
                }
            }
        }
    }
</style>
